import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { numberToWordsPipe } from '../pipes/numberToWords.pipe';

export const options: Partial<IConfig> | (() => Partial<IConfig>) ={};

@NgModule({
  declarations: [
    numberToWordsPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    numberToWordsPipe
  ]
})
export class SharedModule {}

