<div class="main-sidebar">
    <div class="main-sidebar-header">
        <a href="javascript:void(0)" [inlineSVG]="'assets/images/Apperone_Pro.svg'" class="sidebar-logo"></a>
        <div class="sidebar-profile">
            <figure>
                <img [src]="adminDetail.imageUrl" alt="profile-image" (error)="imgErrorHandler($event)">
            </figure>
            <div class="profile-info">
                <h4>{{ adminDetail.firstName+" "+adminDetail.lastName }}</h4>
                <a [href]="'mailto:'+adminDetail.email" class="btn-mailto"> {{ adminDetail.email }} </a>
                <a href="javascript:void(0)" (click)="openFromProfile(account)" class="btn-view-profile">view
                    profile</a>
            </div>
        </div>
    </div>
    <div class="main-side-bar-menu">
        <ng-scrollbar>
            <div *ngIf='!superAdmin' class="sidebar-wrap">
                <div href="javascript:void(0)" class="sidebar-heading">
                    <i [inlineSVG]="'assets/images/icon-trips.svg'"></i><span>Trips</span>
                </div>
                <ul class="sidebar-nav">
                    <li>
                        <a [class.active]="router.url==='/trips'" (click)="changeSidebartwo('manage')" [routerLink]="['/trips']">Manage</a>
                    </li>
                    <li>
                        <a [class.active]="router.url==='/trips/archive'" (click)="changeSidebartwo('archive')" [routerLink]="['/trips/archive']">Archive</a>
                    </li>
                    <li>
                        <a [class.active]="router.url==='/trips/add-new'" (click)="changeSidebartwo('addnew')" [routerLink]="['/trips/add-new']">Add New</a>
                    </li>
                </ul>
            </div>
            <div *ngIf='!superAdmin' class="sidebar-wrap">
                <div href="javascript:void(0)" class="sidebar-heading">
                    <i [inlineSVG]="'assets/images/icon-database.svg'"></i><span>Database</span>
                </div>
                <ul class="sidebar-nav">
                    <li>
                        <a [class.active]="router.url==='/database'" (click)="changeSidebartwo('database')" [routerLink]="['/database']">Manage</a>
                    </li>
                </ul>
            </div>
            <div *ngIf='!superAdmin' class="sidebar-wrap">
                <div href="javascript:void(0)" class="sidebar-heading">
                    <a href="javascript:void(0)" (click)="notificationsModal(notifications)"><i
                            [inlineSVG]="'assets/images/icon-notifications.svg'"></i><span>Notifications</span></a>
                </div>
            </div>
            <div *ngIf='superAdmin' class="sidebar-wrap">
                <div href="javascript:void(0)" class="sidebar-heading">
                    <a [routerLink]="['/admin-management/list-administrator']"><i
                            [inlineSVG]="'assets/images/icon-database.svg'"></i><span>Admin Management</span></a>
                </div>
            </div>
            <div *ngIf='superAdmin' class="sidebar-wrap">
                <div href="javascript:void(0)" class="sidebar-heading">
                    <a [routerLink]="['/all-user-management/all-user-listing']"><i
                            [inlineSVG]="'assets/images/icon-database.svg'"></i><span>All Users</span></a>
                </div>
            </div>
            <div *ngIf='superAdmin' class="sidebar-wrap">
                <div href="javascript:void(0)" class="sidebar-heading">
                    <a [routerLink]="['/app-information/list-app-information']"><i
                            [inlineSVG]="'assets/images/icon-database.svg'"></i><span>App Information</span></a>
                </div>
            </div>
        </ng-scrollbar>
    </div>
    <div class="main-sidebar-footer">
        <div class="sidebar-wrap">
            <div href="javascript:void(0)" class="sidebar-heading">
                <a href="javascript:void(0)" (click)="settingsModal(settings)"><i
                        [inlineSVG]="'assets/images/icon-settings.svg'"></i><span>Settings</span></a>
            </div>
        </div>
    </div>
</div>

<div class="sub-sidebar" *ngIf="adminDetail.userType != 'SUPER_ADMIN' " [ngClass]="{active: shouldShow, inactive: !shouldShow}">
    <div class="manage-trips" *ngIf="manageActive">
        <div *ngIf="!(queryParams.trip && !tripListing.length)" class="sub-sidebar-heading">
            <h3>Manage</h3>
        </div>
        <div class="sub-sidebar-heading">
            <a href="javascript:void(0)" [inlineSVG]="'assets/images/icon-datatable-add.svg'" (click)="folderlisting(folder)"></a>
            <h3 style="margin-left: 7px;margin-bottom: 3px;">Folder</h3>
        </div>
        <div *ngIf="queryParams.trip && !tripListing.length" class="sub-sidebar-heading">
            <a href="javascript:void(0)"  [routerLink]="['/trips']" [inlineSVG]="'assets/images/icon-back-heading.svg'" class="add-btn" style="margin: 20px;"></a>
            <h3>
                {{ currentTrip.tripName }}
            </h3>
        </div>
        <ng-scrollbar>
            <div class="sub-sidebar-inner-menu-heading" style="display: none;">
                <a href="javascript:void(0)" [inlineSVG]="'assets/images/icon-back-heading.svg'" class="back-btn-heading"></a>
                <h4>Washington D.C. Band Trip</h4>
            </div>

            <div *ngFor="let trip of tripListing;index as i" class="sub-menu-wrap inside-active " [ngClass]="{'active':currentTripOptionDisplay === trip.chTrip?.tripName}" (click)="checkTarget($event)">
                <h4>{{trip.chTrip?.tripName}} </h4>
                <ul class="sub-menu-nav">
                    <li class="toggle">
                        <a [routerLink]="['/trips/info']" [queryParams]="{ trip: trip.chTrip?.id, edit: 1 }">Info</a>
                    </li>
                    <li class="toggle">
                        <a [routerLink]="['/trips/attendees']" [queryParams]="{trip:  trip.chTrip?.id}">Attendees</a>
                        <ul class="inner-menu">
                            <li>
                                <a [routerLink]="['/trips/attendees/teams']" [queryParams]="{trip:  trip.chTrip?.id}">Teams</a>
                            </li>
                        </ul>
                    </li>
                    <!-- <li class="toggle">
                        <a [routerLink]="['/trips/travel']" [queryParams]="{ trip: trip.chTrip?.id, edit: 1 }">Travel</a>
                    </li> -->
                    <li class="toggle">
                        <a [routerLink]="['/trips/itinerary']" [queryParams]="{ trip: trip.chTrip?.id, edit:1 }">Itinerary</a>
                    </li>
                    <li class="toggle">
                        <a [routerLink]="['/trips/chats']" [queryParams]="{trip:  trip.chTrip?.id}">Chat</a>
                    </li>
                    <li class="toggle">
                        <a [routerLink]="['/trips/photos']" [queryParams]="{trip:  trip.chTrip?.id}">Photos</a>
                    </li>
                    <li class="toggle" *ngIf="trip.chTrip?.tripStatus == 'OPEN'">
                        <a [routerLink]="['/trips/notification']" [queryParams]="{trip:  trip.chTrip?.id}">Notifications</a>
                    </li>
                    <li class="toggle" *ngIf="trip.chTrip?.tripStatus == 'DRAFT'">
                        <a [routerLink]="['/trips/complete']" [queryParams]="{trip:  trip.chTrip?.id}">Complete Trip</a>
                    </li>
                </ul>
            </div>

            <div class="sub-menu-wrap inside-active active" *ngIf="queryParams.trip && !tripListing.length">
                <h4></h4>
                <ul class="sub-menu-nav">
                    <li class="toggle">
                        <a [routerLink]="['/trips/info']" [queryParams]="{ trip: currentTrip.id, edit: 1 }">Info</a>
                    </li>
                    <li class="toggle">
                        <a [routerLink]="['/trips/attendees']" [queryParams]="{trip:  currentTrip.id}">Attendees</a>
                        <ul class="inner-menu">
                            <li>
                                <a [routerLink]="['/trips/attendees/teams']" [queryParams]="{trip:  currentTrip.id}">Teams</a>
                            </li>
                        </ul>
                    </li>
                    <!-- <li class="toggle">
                        <a [routerLink]="['/trips/travel']" [queryParams]="{ trip: currentTrip.id, edit: 1 }">Travel</a>
                    </li> -->
                    <li class="toggle">
                        <a [routerLink]="['/trips/itinerary']" [queryParams]="{ trip: currentTrip.id, edit:1 }">Itinerary</a>
                    </li>
                    <li class="toggle">
                        <a [routerLink]="['/trips/chats']" [queryParams]="{trip:  currentTrip.id}">Chat</a>
                    </li>
                    <li class="toggle">
                        <a [routerLink]="['/trips/photos']" [queryParams]="{trip: currentTrip.id}">Photos</a>
                    </li>
                    <li class="toggle" *ngIf="!queryParams.archive">
                        <a [routerLink]="['/trips/notification']" [queryParams]="{trip:  currentTrip.id}">Notifications</a>
                    </li>
                </ul>
            </div>
            <!-- Please done remove this blank div from here -->
            <div  class="sub-menu-wrap" (click)="checkTarget($event)"></div>
            <div *ngFor="let folder of Folders" class="sub-menu-wrap" (click)="checkTarget($event)">
                <div class="folder-heading">
                    <i [inlineSVG]="'assets/images/icon-folder-empty.svg'"></i>
                    <h4>{{folder.name}}</h4>
                    <div class="deletegroups" style="top: 11px !important;">
                        <i [inlineSVG]="'assets/images/icn-edit.svg'" (click)="editFolder(folder)"></i>
                        <i [inlineSVG]="'assets/images/icon-more-delete.svg'" (click)="deleteFolder(folder.id)"></i>
                    </div>
                </div>
                <ul class="sub-menu-nav folder-menu">
                    <li class="toggle " *ngFor="let trip of folder.chTripDTO">
                        <a class="remove-arrow" href="javascript:void(0)">{{trip.tripName}}</a>
                        <ul class="sub-menu-nav">
                            <li class="toggle remove-arrow">
                                <a [routerLink]="['/trips/info']" [queryParams]="{ trip: trip.id, edit: 1 }">Info</a>
                            </li>
                            <li class="toggle">
                                <a class="remove-arrow" [routerLink]="['/trips/attendees']" [queryParams]="{trip:  trip.id}">Attendees</a>
                                <ul class="inner-menu">
                                    <li class="remove-arrow">
                                        <a [routerLink]="['/trips/attendees/teams']" [queryParams]="{trip:  trip.id}">Teams</a>
                                    </li>
                                </ul>
                            </li>
                            <!-- <li class="toggle remove-arrow">
                                <a  [routerLink]="['/trips/travel']" [queryParams]="{ trip: trip.id, edit: 1 }">Travel</a>
                            </li> -->
                            <li class="toggle remove-arrow">
                                <a  [routerLink]="['/trips/itinerary']" [queryParams]="{ trip: trip.id, edit:1 }">Itinerary</a>
                            </li>
                            <li class="toggle remove-arrow">
                                <a [routerLink]="['/trips/chats']" [queryParams]="{trip:  trip.id}">Chat</a>
                            </li>
                            <li class="toggle remove-arrow">
                                <a [routerLink]="['/trips/photos']" [queryParams]="{trip:  trip.id}">Photos</a>
                            </li>
                            <li class="toggle remove-arrow">
                                <a [routerLink]="['/trips/notification']" [queryParams]="{trip:  trip.id}">Notifications</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </ng-scrollbar>
    </div>
    <div class="archive-trips toggle" *ngIf="archiveActive">
        <div class="sub-sidebar-heading">
            <h3>Archive</h3>
        </div>
        <ng-scrollbar>
            <!-- <div class="sub-menu-wrap" (click)="checkTarget($event)"> -->
            <div *ngFor="let trip of tripListing" class="sub-menu-wrap inside-active"  [ngClass]="{'active':currentTripOptionDisplay === trip.chTrip?.tripName}" (click)="checkTarget($event)" >
                    <h4>{{trip.chTrip?.tripName}}</h4>
                <ul class="sub-menu-nav">
                    <li class="toggle">
                        <!-- <a [routerLink]="['/trips/info']" [queryParams]="{ archive: 1 }">Info</a>      -->
                        <a [routerLink]="['/trips/info']" [queryParams]="{ trip: trip.chTrip?.id, archive: 1 }">Info</a>                       
                    </li>
                    <li class="toggle">
                        <a [routerLink]="['/trips/attendees']" [queryParams]="{ trip: trip.chTrip?.id, archive: 1 }">Attendees</a>
                    </li>
                    <!-- <li class="toggle">
                        <a [routerLink]="['/trips/travel']" [queryParams]="{ trip: trip.chTrip?.id, archive: 1 }">Travel</a>
                    </li> -->
                    <li class="toggle">
                        <a [routerLink]="['/trips/itinerary']" [queryParams]="{ trip: trip.chTrip?.id, archive: 1 }">Itinerary</a>
                    </li>
                    <li class="toggle">
                        <a [routerLink]="['/trips/chats']" [queryParams]="{ trip: trip.chTrip?.id, archive: 1 }">Chat</a>
                    </li>
                    <li class="toggle">
                        <a [routerLink]="['/trips/photos']" [queryParams]="{ trip: trip.chTrip?.id, archive: 1 }">Photos</a>
                    </li>
                </ul>
            </div>
            <!-- <div *ngFor="let folder of Folders" class="sub-menu-wrap"  (click)="checkTarget($event)"  >
                    <div class="folder-heading">
                        <i [inlineSVG]="'assets/images/icon-folder-empty.svg'"></i>
                        <h4>{{folder.name}}</h4>
                    </div>
                    <ul class="sub-menu-nav folder-menu">
                        <li class="toggle " *ngFor="let trip of folder.chTripDTO">
                            <a href="javascript:void(0)">{{trip.tripName}}</a>
                        </li>

                    </ul>
                </div> -->

        </ng-scrollbar>
    </div>
    <div class="add-new-trips" *ngIf="addNewActive">
        <div class="sub-sidebar-heading">
            <h3>Add New</h3>
        </div>
        <ng-scrollbar>
            <div class="sub-menu-flow " [ngClass]="{'filled': newTripStatus >= 1, 'current': newTripStatus == 0}">
                <h5>Basic Information</h5>
                <span></span>
                <span></span>
            </div>
            <div class="sub-menu-flow current" [ngClass]="{'filled': newTripStatus >= 2, 'current': newTripStatus == 1}">
                <h5>Invites</h5>
                <span></span>
                <span></span>
            </div>
            <!-- <div class="sub-menu-flow" [ngClass]="{'filled': newTripStatus >= 3, 'current': newTripStatus == 2}">
                <h5>Travel</h5>
                <span></span>
                <span></span>
            </div> -->
            <div class="sub-menu-flow" [ngClass]="{'filled': newTripStatus >= 4, 'current': newTripStatus == 3}">
                <h5>Itinerary</h5>
                <span></span>
                <span></span>
            </div>
            <div class="sub-menu-flow" [ngClass]="{'filled': newTripStatus >= 5 ,'current': newTripStatus == 4}">
                <h5>Complete</h5>
                <span></span>
                <span></span>
            </div>
        </ng-scrollbar>
    </div>
    <div class="manage-database" *ngIf="databaseActive">
        <div class="sub-sidebar-heading">
            <h3>Manage</h3>
        </div>
        <ng-scrollbar>
            
            <ul class="database-nav">
                <li *ngFor="let group of Grouplisting" class="sub-menu-wrap" (click)="checkTarget($event)">
                    <div class="folder-heading"  (click)="getGroupUsers(group.id,$event)">
                        <h4>{{group.groupName}}</h4>
                        <div class="deletegroups">
                            <i [inlineSVG]="'assets/images/icn-edit.svg'" (click)="editGroupName(group);;$event.stopPropagation()"></i>
                            <i [inlineSVG]="'assets/images/icon-more-delete.svg'" (click)="deleteGroup(group.id);;$event.stopPropagation()"></i>
                        </div>
                    </div>
                    <ul class="sub-menu-nav folder-menu" *ngIf="GroupUsers.length > 0">
                        <li class="toggle remove-arrow active">
                            <ul class="sub-menu-nav ">
                                <li class="toggle remove-arrow" *ngFor="let user of GroupUsers">
                                   {{user.firstName +" "+user.lastName}}
                                   <span style="display: inline-block; vertical-align: middle;margin-left: 5px;">
                                       <i [inlineSVG]="'assets/images/icon-more-delete.svg'" (click)="removeUserFromGroup(user);$event.stopPropagation()"></i>
                                   </span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <!-- <li *ngFor="let group of Grouplisting">
                    <a href="javascript:void(0)" (click)="getGroupUsers(group.id)">{{group.groupName}} 
                        <div class="deletegroups">
                            <i [inlineSVG]="'assets/images/icn-edit.svg'" (click)="editGroup(group)"></i>
                            <i [inlineSVG]="'assets/images/icon-more-delete.svg'" (click)="deleteGroup(group.id)"></i>
                        </div>
                    </a>
                   
                </li> -->
                <li>
                    <form class="add-grp-name-form">
                        <input type="text"  #addgroupname (input)="groupname = $event.target.value;" class="add-grp-name-filed" placeholder="Group Name">
                        <div *ngIf="groupsubmitted && groupname == ''" style="color: red;">
                            <div>Please provide group name.</div>
                        </div>
                    </form>
                </li>
                <li  (click)="addnewGroup('add')">
                    <div class="add-grp-btn"><i [inlineSVG]="'assets/images/icon-manage-add.svg'"></i><strong>Add New</strong></div>                
                </li>
            </ul>
        </ng-scrollbar>
    </div>
    <a href="javascript:void(0)" class="sidebar-arrow" (click)="sidebarToggle()" [ngClass]="{active: shouldShow, inactive: !shouldShow}"><i
            [inlineSVG]="'assets/images/icon-sidebar-arrow.svg'"></i></a>
</div>

<!-- Terms of use Popup -->
<ng-template #groups>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalGroupname.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <form>
                <ul class="form-fields-listing">
                    <li>
                        <div class="form-grp">
                            <label>Group Name</label>
                            <input type="text" [value]="groupnameadd" (input)="groupnameadd = $event.target.value;" placeholder="Group Name" class="input-grp" style="width: 200% !important;">
                            <div class="invalid-feedback">
                                <div *ngIf="groupsubmittedupdate && groupnameadd == ''">
                                    Please provide group name.
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <input type="button" (click)="addnewGroup('update')" value="Update" class="btn-blue min-btn">
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    </div>
</ng-template>


<!-- Details Popup -->
<ng-template #folder>
    <div class="details-main">
      <button style="z-index: 9999999;" type="button" class="close" aria-label="Close" (click)="isCreateEmptyFolderSubmitted = false; modalFolder.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- <a href="javascript:void(0)" class="modal-close-btn" (click)="addfolderSubmitted = false; isCreateEmptyFolderSubmitted = false; modalFolder.hide()"
        [inlineSVG]="'assets/images/icon-popup-close.svg'"></a> -->
      <div class="modal-body">
        <div class="tabs-details">
          <div class="modal-header" style="margin-top: 17px;"> </div>
          <div>
            <form [formGroup]="createEmptyFolderForm" (ngSubmit)="createEmptyFolder()">
              <ul class="form-fields-listing">
                <li>
                  <div class="form-grp">
                    <label>Folder Name</label>
                    <input type="text" formControlName="folderName" placeholder="Folder Name" class="input-grp" [ngClass]="{ 'is-invalid': isCreateEmptyFolderSubmitted && createEmptyFolderForm.controls.folderName.errors }">
                    <div class="invalid-feedback" *ngIf="isCreateEmptyFolderSubmitted && createEmptyFolderForm.controls.folderName.errors">
                      <div *ngIf="createEmptyFolderForm.hasError('required','folderName')">
                          Please provide folder name.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <input type="submit" value="Save"   class="btn-blue min-btn" >
                  </div>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

<!-- Settings Popup -->
<ng-template #settings>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalSettings.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <h4>Settings</h4>
        </div>
        <ng-scrollbar>
            <div class="modal-body">
                <ul class="settings-listing">
                    <li>
                        <a href="javascript:void(0)" (click)="accountModal(account)">
                            <span>My Account</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li *ngIf="adminDetail.userType != 'SUPER_ADMIN'">
                        <a href="javascript:void(0)" (click)="licenseModal(license)">
                            <span>License</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="changepasswordModal(changepassword)">
                            <span>Change Password</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="aboutModal(about)">
                            <span>About</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="privacyModal(privacy)">
                            <span>Privacy Policy</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="termsModal(terms)">
                            <span>Terms of Use</span><i [inlineSVG]="'assets/images/icon-settings-right-arrow.svg'"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </ng-scrollbar>
        <div class="modal-footer">
            <a [routerLink]="['/login']" class="btn-logout" (click)="logout()"><i
                    [inlineSVG]="'assets/images/icon-logout.svg'"></i>Log out</a>
        </div>
    </div>
</ng-template>

<!-- My Account Popup -->
<ng-template #account>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalAccount.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <form [formGroup]="editAdminForm" (ngSubmit)="updateProfile()">
            <div class="modal-header">
                <div class="header-info">
                    <a href="javascript:void(0)" *ngIf="isHidden" (click)="modalAccount.hide()"><i
                            [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                    <a href="javascript:void(0)" *ngIf="!isHidden" (click)="modalAccount.hide()"><i
                            [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Go Back</a>
                    <h3>My Account</h3>
                    <input *ngIf="isEditing" type="submit" value="Update" class="btn-edit-save">
                    <input *ngIf="!isEditing" type="button" (click)="edit()" value="Edit" class="btn-edit-save">
                    <!-- <a href="javascript:void(0)" class="btn-edit-save">EDIT</a> -->
                </div>
            </div>
            <ng-scrollbar>
                <div class="modal-body">
                    <div class="my-account-form">
                        <div class="auth-wrap">
                            <div class="auth-form-fields">
                                <div class="upload-profile">
                                    <figure>
                                        <img class="profile-placeholder-img" [src]="selectedImage" alt="profile-placeholder-img" (error)="imgErrorHandler($event)" />
                                    </figure>
                                    <!-- <input type="file" id="upload-profile-pic" (change)="onSelectImage($event)"> -->
                                    <label *ngIf="isEditing" for="upload-profile-pic" (click)="onFileSelect()" [inlineSVG]="'assets/images/icon-plus.svg'"></label>
                                </div>
                                <ul>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" [readonly]="!isEditing" formControlName="firstName" placeholder="First Name" [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.firstName.errors }">
                                            <div class="invalid-feedback" *ngIf="profileSumitted && editAdminForm.controls.firstName.errors">
                                                <div *ngIf="editAdminForm.hasError('required','firstName')">
                                                    Please provide first name.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" [readonly]="!isEditing" formControlName="lastName" placeholder="Last Name" [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.lastName.errors }">
                                            <div class="invalid-feedback" *ngIf="profileSumitted && editAdminForm.controls.lastName.errors">
                                                <div *ngIf="editAdminForm.hasError('required','lastName')">
                                                    Please provide last name.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="email" class="input-grp" formControlName="email" placeholder="Email" disabled [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.email.errors }">
                                            <div class="invalid-feedback" *ngIf="profileSumitted && editAdminForm.controls.email.errors">
                                                <div *ngIf="editAdminForm.hasError('required','email')">
                                                    Please provide email.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" formControlName="phone" placeholder="Phone Number" disabled [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.phone.errors }">
                                            <div class="invalid-feedback" *ngIf="profileSumitted && editAdminForm.controls.phone.errors">
                                                <div *ngIf="editAdminForm.hasError('required','phone')">
                                                    Please provide phone number.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" [readonly]="!isEditing" formControlName="position" placeholder="Position" [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.position.errors }">
                                            <div class="invalid-feedback" *ngIf="profileSumitted && editAdminForm.controls.position.errors">
                                                <div *ngIf="editAdminForm.hasError('required','position')">
                                                    Please provide position.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="text" class="input-grp" [readonly]="!isEditing" formControlName="organization" placeholder="Organization" [ngClass]="{ 'is-invalid': profileSumitted && editAdminForm.controls.organization.errors }">
                                            <div class="invalid-feedback" *ngIf="profileSumitted && editAdminForm.controls.organization.errors">
                                                <div *ngIf="editAdminForm.hasError('required','organization')">
                                                    Please provide organization.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
            <div class="modal-footer">

            </div>
        </form>
    </div>
</ng-template>

<!-- License Popup -->
<ng-template #license>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalLicense.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <form>
            <div class="modal-header">
                <div class="header-info">
                    <a href="javascript:void(0)" (click)="modalLicense.hide()"><i
                            [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                    <h3>License</h3>
                </div>
            </div>
            <ng-scrollbar>
                <div class="modal-body">
                    <div class="my-account-form">
                        <div class="auth-wrap">
                            <div class="auth-form-fields">
                                <ul>
                                    <li>
                                        <div class="form-grp">
                                            <div class="license-wrap">
                                                <span>{{ adminDetail.adminLicenceKey }}</span>
                                                <strong>{{ adminDetail.activated ? "Active" : "Inactive"}}</strong>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
            <div class="modal-footer">

            </div>
        </form>
    </div>
</ng-template>

<!-- Change Password Popup -->
<ng-template #changepassword>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalChangepassword.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
            <div class="modal-header">
                <div class="header-info">
                    <a href="javascript:void(0)" (click)="modalChangepassword.hide()"><i
                            [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                    <h3>Change Password</h3>
                    <input type="submit" value="SAVE" class="btn-edit-save">
                    <!-- <a href="javascript:void(0)" class="btn-edit-save">SAVE</a> -->
                </div>
            </div>
            <ng-scrollbar>
                <div class="modal-body">
                    <div class="my-account-form">
                        <div class="auth-wrap">
                            <div class="auth-form-fields">
                                <ul>
                                    <li>
                                        <div class="form-grp">
                                            <input type="password" formControlName="currentPassword" class="input-grp" placeholder="Current Password" [ngClass]="{ 'is-invalid': resetPasswordSubmited && changePasswordForm.controls.currentPassword.errors }">
                                            <div class="invalid-feedback" *ngIf="resetPasswordSubmited && changePasswordForm.controls.currentPassword.errors">
                                                <div *ngIf="changePasswordForm.hasError('required','currentPassword')">
                                                    Please provide current password.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="password" formControlName="newPassword" class="input-grp" placeholder="New Password" [ngClass]="{ 'is-invalid': resetPasswordSubmited && changePasswordForm.controls.newPassword.errors }">
                                            <div class="invalid-feedback" *ngIf="resetPasswordSubmited && changePasswordForm.controls.newPassword.errors">
                                                <div *ngIf="changePasswordForm.hasError('required','newPassword')">
                                                    Please provide new password.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-grp">
                                            <input type="password" formControlName="confirmPassword" class="input-grp" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': (resetPasswordSubmited && changePasswordForm.controls.confirmPassword.errors) && (changePasswordForm.value.newPassword != changePasswordForm.value.confirmPassword) }">
                                            <div class="invalid-feedback" *ngIf="resetPasswordSubmited && changePasswordForm.controls.confirmPassword.errors">
                                                <div *ngIf="changePasswordForm.hasError('required','confirmPassword')">
                                                    Please confirm password
                                                </div>
                                            </div>
                                            <div class="invalid-feedback" *ngIf="!changePasswordForm.hasError('required','confirmPassword') && (changePasswordForm.value.newPassword != changePasswordForm.value.confirmPassword) && resetPasswordSubmited">
                                                <div>
                                                    Password did not match.
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
            <div class="modal-footer">

            </div>
        </form>
    </div>
</ng-template>

<!-- About Popup -->
<ng-template #about>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalAbout.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <div class="header-info">
                <a href="javascript:void(0)" (click)="modalAbout.hide()"><i
                        [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                <h3>About</h3>
            </div>
        </div>
        <ng-scrollbar>
            <div class="modal-body">
                <div class="static-content" [innerHTML]="aboutDetail">
                </div>
            </div>
        </ng-scrollbar>
        <div class="modal-footer">

        </div>
    </div>
</ng-template>

<!-- Privacy Policy Popup -->
<ng-template #privacy>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalPrivacy.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <div class="header-info">
                <a href="javascript:void(0)" (click)="modalPrivacy.hide()"><i
                        [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                <h3>Privacy Policy</h3>
            </div>
        </div>
        <ng-scrollbar>
            <div class="modal-body">
                <div class="static-content" [innerHTML]="privacyDetail">
                </div>
            </div>
        </ng-scrollbar>
        <div class="modal-footer">

        </div>
    </div>
</ng-template>

<!-- Terms of use Popup -->
<ng-template #terms>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalTerms.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-header">
            <div class="header-info">
                <a href="javascript:void(0)" (click)="modalTerms.hide()"><i
                        [inlineSVG]="'assets/images/icon-settings-left-arrow.svg'"></i>Settings</a>
                <h3>Terms of Use</h3>
            </div>
        </div>
        <ng-scrollbar>
            <div class="modal-body">
                <div class="static-content" [innerHTML]="termsDetail">
                </div>
            </div>
        </ng-scrollbar>
        <div class="modal-footer">

        </div>
    </div>
</ng-template>


<!-- Notifications Popup -->
<ng-template #notifications>
    <div class="settings-main">
        <button type="button" class="close" aria-label="Close" (click)="modalNotifications.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <form>
            <div class="modal-header">
                <h4>Notifications</h4>
                <!-- <a href="javascript:void(0)" class="btn-clear-all">Clear All</a> -->
            </div>
            <ng-scrollbar>
                <div class="modal-body">
                    <div class="notifications-main" *ngIf="AdminNotifications.length > 0">
                        <div *ngFor="let notification of AdminNotifications">
                            <div class="notifications-wrap">
                                <h4 *ngIf="notification[0].istoday">Today</h4>
                                <h4 *ngIf="notification[0].isyesterday">Yesterday</h4>
                                <h4 *ngIf="!notification[0].isyesterday && !notification[0].istoday">{{notification[0].createdDate | date }}</h4>
                                <ul class="notifications-listing">
                                    <li  *ngFor="let not of notification">
                                        <div class="notifications-listing-wrap">
                                            <strong>{{not.notificationTitle}}</strong>
                                            <p>{{not.notificationDescription}}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="notifications-wrap">
                            <h4>Yesterday</h4>
                            <ul class="notifications-listing">
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="notifications-wrap">
                            <h4>23/04/2020</h4>
                            <ul class="notifications-listing">
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="notifications-listing-wrap">
                                        <strong>Title</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                            anim id est laborum.
                                        </p>
                                    </div>
                                </li>
                            </ul> -->
                        <!-- </div> -->
                    </div>
                    <div class="notifications-main" *ngIf="AdminNotifications.length == 0">
                        No Notifications
                    </div>
                </div>
            </ng-scrollbar>
            <div class="modal-footer">
                <a href="javascript:void(0)" class="btn-blue min-btn" (click)="modalNotifications.hide()">Done</a>
            </div>
        </form>
    </div>
</ng-template>

