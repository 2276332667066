import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {

    constructor(
        public router: Router
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let userData = JSON.parse(localStorage.getItem('cuserData') || '{}')
        if (userData.token_id) {
            req = req.clone({
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + userData.token_id
                })
            });
        } else {
            req = req.clone();
        }
        return next.handle(req).pipe(
            map(event => event),
            catchError(error => {
                if (error.status === 401) {
                    localStorage.removeItem('cuserData');
                    this.router.navigate(['/login']);
                }
                throw error;
            })
        )
    }
}


