import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarService {

  private messageSource = new BehaviorSubject(false);
  currentStatus = this.messageSource.asObservable();

  private newTripSource = new BehaviorSubject(0);
  newTripStatus = this.newTripSource.asObservable();

  private sidebarOptionSource = new BehaviorSubject("manage");
  sidebarOption = this.sidebarOptionSource.asObservable();

  private triplisting = new BehaviorSubject([]);
  sideTrips = this.triplisting.asObservable();

  private grouplisting = new BehaviorSubject([]);
  sideGroups = this.grouplisting.asObservable();

  private grouplistingSideBar = new BehaviorSubject([]);
  sideGroupsSideBar = this.grouplistingSideBar.asObservable();

  private folderlisting = new BehaviorSubject([]);
  folders = this.folderlisting.asObservable();

  private folderlistingSidebar = new BehaviorSubject([]);
  foldersSideBar = this.folderlistingSidebar.asObservable();

  private emptyFolderSource = new BehaviorSubject({status:false,data:{}});
  emptyFolderStatus = this.emptyFolderSource.asObservable();


  private notifyFolderDelete = new BehaviorSubject({});
  notifyFolder = this.notifyFolderDelete.asObservable();


  private openTripOptionsSource = new BehaviorSubject(null);
  openTripOptions = this.openTripOptionsSource.asObservable();


  private groupDeleteOptionsSource = new BehaviorSubject(null);
  groupDeleteOptions = this.groupDeleteOptionsSource.asObservable();

  


  constructor() { }

  changeStatus(status: boolean) {
      this.messageSource.next(status);
  }
  getSidebar() {
  	  return this.currentStatus;
  }

  getTripStatus() {
      return this.newTripStatus;
  }

  setTripStatus(status:number) {
      this.newTripSource.next(status);
  }

  getSidebarOption() {
      return this.sidebarOption;
  }

  setSidebarOption(option) {
      return this.sidebarOptionSource.next(option);
  }


  setSideBarTrips(trips){
    this.triplisting.next(trips);
  }

  getSideBarTrips(){
      return this.triplisting;
  }

  setSideBarGroups(groups){
    this.grouplisting.next(groups);
  }

  getSideBarGroups(){
    return this.grouplisting;
  }

  
  setSideBarGroupsFromSideBar(groups){
    this.grouplistingSideBar.next(groups);
  }

  getSideBarGroupsFromSideBar(){
    return this.grouplistingSideBar;
  }

  setSideBarFolders(folder){
    this.folderlisting.next(folder);
  }

  getSideBarFolders(){
    return this.folderlisting;
  }

  setEmptyFolder(status) {
      this.emptyFolderSource.next(status);
  }

  getEmptyFolder() {
      return this.emptyFolderStatus;
  }

  setSideBarFoldersFromSideBar(folders){
    this.folderlistingSidebar.next(folders);
  }

  getSideBarFoldersFromSideBar(){
    return this.folderlistingSidebar;
  }
  
  setOpenSidebarTripOption(name){
    this.openTripOptionsSource.next(name);
  }

  getOpenTripSidebarOption(){
    return this.openTripOptions;
  }
  
  setGroupStatusOption(deleted) {
    this.groupDeleteOptionsSource.next(deleted)
  }
  getGroupOption() {
    return this.groupDeleteOptions;
  }


  notifyFolderDeleted(data){
    this.notifyFolderDelete.next(data);
  }

  getNotifyFolderDeleted(){
    return this.notifyFolder;
  }
}