import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { canLogin } from './shared/gaurds/login.gaurd';
import { canAccess } from './shared/gaurds/access.gaurd';
import { P404Component } from './pages/error/404.component';

import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
{
  path: '',
  redirectTo: 'login',
  pathMatch: 'full',
},
{
  path: '404',
  component: P404Component,
  data: {
    title: 'Page 404'
  }
},
{
    path: '',
    component: DefaultLayoutComponent,
    data: {
        title: 'Apperone'
    },
    canActivate: [canAccess],
    children: [
        {
            path: 'trips',
            loadChildren: () => import('./pages/trips/trips.module').then(m => m.TripsModule),
            canActivate: [NgxPermissionsGuard],
            data: {   
                permissions: {
                  only: ['ADMIN'],
                  redirectTo: '404'
                }
            },
        },
        {
          path: 'admin-management',
          loadChildren: () => import('./pages/admin-management/admin-management.module').then(m => m.AdminManagementModule),
          canActivate: [NgxPermissionsGuard],
          data: {   
              permissions: {
                  only: ['SUPER_ADMIN'],
                  redirectTo: '404'
              }
          },
        },
        {
          path: 'all-user-management',
          loadChildren: () => import('./pages/all-user-management/all-user-management.module').then(m => m.AllUserManagementModule),
          canActivate: [NgxPermissionsGuard],
          data: {   
              permissions: {
                  only: ['SUPER_ADMIN'],
                  redirectTo: '404'
              }
          },
        },
        {
          path: 'app-information',
          loadChildren: () => import('./pages/app-information/app-information.module').then(m => m.AppInformationModule),
          canActivate: [NgxPermissionsGuard],
          data: {   
              permissions: {
                  only: ['SUPER_ADMIN'],
                  redirectTo: '404'
              }
          },
        },
        {
          path: 'database',
          loadChildren: () => import('./pages/database/database.module').then(m => m.DatabaseModule),
          canActivate: [NgxPermissionsGuard],
          data: {   
              permissions: {
                  only: ['ADMIN'],
                  redirectTo: '404'
              }
          },
        }
    ]
},
{
  path: '',
  component: AuthLayoutComponent,
  data: {
      title: 'Apperone'
  },

  canActivate: [canLogin],
  children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'tripDetail',
        loadChildren: () => import('./pages/tripDetail/tripDetail.module').then(m => m.TripDetailModule)
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./pages/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule)
      },
      {
        path: 'create-account',
        loadChildren: () => import('./pages/create-account/create-account.module').then(m => m.CreateAccountModule)
      },
      {
        path: 'verification-code',
        loadChildren: () => import('./pages/verification-code/verification-code.module').then(m => m.VerificationCodeModule)
      },
      {
        path: 'profile-image',
        loadChildren: () => import('./pages/profile-image/profile-image.module').then(m => m.ProfileImageModule)
      },
      {
        path: 'license-key',
        loadChildren: () => import('./pages/license-key/license-key.module').then(m => m.LicenseKeyModule)
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      { path: '**', component: P404Component }
  ]
 }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
