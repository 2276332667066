import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TripService {

    constructor(
        public http: HttpClient,
        public router: Router,
    ) { }

    public createTripStepOne(data) {
        return this.http.post<any>(environment.base_url + "ch-trips/add/step1",data)
    }
    
    public updateTripDetails(data){
        return this.http.post<any>(environment.base_url + "ch-trips/trip/update",data)
    }
    
    public createTripStepTwo(data) {
        return this.http.post<any>(environment.base_url + "ch-trips/add/step2",data)
    }

    public getTavelItinerary(data) {
        return this.http.post<any>(environment.base_url + `ch-trips/get/travel/itinerary?tripId=${data.tripID}&page=${data.page}&size=${data.size}&search=${data.search}`,{ observe: "response" })
    }
    public addNewTravel(data){
        return this.http.post<any>(environment.base_url + "ch-trips/add/travel/itinerary",data)
    }

    public getTripAttendees(data){
        // if(data.sort){
        //     return this.http.post<any>(environment.base_url + `ch-trips/attendees/getByTrip?page=${data.page}&size=${data.size}&sort=${data.sort+","+data.sortType}&tripId=${data.tripId}&tripUserType=${data.userType}&teamId=${data.teamId}&search=${data.search}`,{ observe: "response" });
        // }else{
            return this.http.post<any>(environment.base_url + `ch-trips/attendees/getByTrip?page=${data.page}&size=${data.size}&tripId=${data.tripId}&tripUserType=${data.userType}&teamId=${data.teamId}&search=${data.search}`,{ observe: "response" });
        // }
    }

    public getTripAttendeesNew(data){
        return this.http.post<any>(environment.base_url+ `ch-trips/attendees/getByDefaultTeam?tripId=${data.tripId}&teamId=${data.teamId}`,{ observe: "response" });
    }
    

    public getItinerary(id) {
        return this.http.post<any>(environment.base_url + `ch-trips/get/all/itinerary?tripId=${id}`,{})
    }

    public addItineraryEvent(data) {
        return this.http.post<any>(environment.base_url + `ch-trips/add/event/itinerary`,data)
    }
    
    public addTraelMember(data) {
        return this.http.post<any>(environment.base_url + `ch-trips/travel/member/add`,data)
    }

    public getTravelMember(data){
        return this.http.post<any>(environment.base_url + `ch-trips/travel/member/get?travelId=${data.travelId}`,{ observe: "response" });   
    }

    public snetTripNotification(tripID){
        return this.http.post<any>(environment.base_url + `ch-trip/send/email/`+tripID,null);
    }

    public getTripListing(data){
        return this.http.get<any>(environment.base_url + `ch-trip/admin/byStatusIn?statuses=${data.status}&size=${data.size}&page=${data.page}&search=${data.search}`,{ observe: "response" })
    }

    public getTripDetails(id) {
        return this.http.get<any>(environment.base_url + 'ch-trip/'+id)
    }

    public getTravel(id) {
        return this.http.get<any>(environment.base_url + 'ch-trips/get/itinerary/'+id)
    }

    public getTripTeams(id){
        return this.http.get<any>(environment.base_url + 'ch-trip/teams/'+id)
    }
    
    public getTeamMembers(teamID){
        return this.http.get<any>(environment.base_url + 'ch-trip/team/'+teamID)
    }

    public getTravelLegs(id) {
        return this.http.get<any>(environment.base_url + 'ch-travel-legs/getByTravel/'+id)
    }

    public getAdminGroups() {
        return this.http.get<any>(environment.base_url + 'ch-user/admin/groups')
    }

    public getAllFolders(){
        return this.http.get<any>(environment.base_url + 'admin/folders')
    }

    public addnewFolder(data){
        return this.http.post<any>(environment.base_url + `admin/folder/create`,data)
    }

    public updateFolder(data){
        return this.http.put<any>(environment.base_url + `folder/update/${data.id}/${data.folderName}`,null)
    }

    public updateTripFolder(data){
        return this.http.post<any>(environment.base_url + `add/trip/folder`,data)
    }

    public movetoArchive(ids){
        return this.http.get<any>(environment.base_url + `ch-trip/archive/?id=${ids}`)
    }

    public deleteTrips(ids){
        return this.http.get<any>(environment.base_url + `ch-trip/delete/?id=${ids}`)
    } 

    public addTeam(data) {
         return this.http.post<any>(environment.base_url + `ch-trip/team/add`,data)
    }

    public addtripAttendeeIds(data){
        return this.http.post<any>(environment.base_url + `ch-trip/team/member/add`,data)
    }

    public updateTeam(data){
        return this.http.post<any>(environment.base_url + `ch-trip/team/edit`,data)
    }

    public getTripPhotos(tripId){
        return this.http.get<any>(environment.base_url + `ch-trip/photos/${tripId}`)
    }

    public deleteTravelItenery(ids) {
        return this.http.get<any>(environment.base_url + `ch-itinerary/delete?id=${ids}`)
    }

    public uploadTripPhotos(data){
        return this.http.post<any>(environment.base_url + `ch-trip-photo/upload`,data)
    }

    public getNotificationUsers(data){
        return this.http.get<any>(environment.base_url + `ch-admin-users/getNotificationUsers?userType=${data.userType}&tripId=${data.tripId}`);
    }

    public sendCustomNotification(data){
        return this.http.post<any>(environment.base_url + `ch-admin-users/sendCustomNotification`,data);
    }

    public getTrip(data) {
        return this.http.get<any>(environment.base_url + `ch-trip/super-admin/getTrips?licenceKey=${data.licenceKey}&userId=${data.userId}&userType=${data.userType}`);
    }

    public getEmergencyContact(id) {
        return this.http.get<any>(environment.base_url + `ch-user/emergency-contact/${id}`);   
    }

    public addAttendeesmail(data) {
        return this.http.post<any>(environment.base_url + `ch-trip/send/email/users?tripId=${data.tripId}&userIds=${String(data.userIds)}`,{});   
    }

    public deletePhoto(data){
        return this.http.post<any>(environment.base_url + `ch-trip/photos/delete?id=${data.id}&tripId=${data.tripId}`,null);
    }

    public getAdminAsLeader(id){
        return this.http.get<any>(environment.base_url + `ch-trip/team/getAdminAsLeader?teamId=${id}`);
    }

    public setAdminAsLeader(id){
        return this.http.post<any>(environment.base_url + `ch-trip/team/addAdminAsLeader?teamId=${id}`,null);
    }

    public removeAdminAsLeader(id){
        return this.http.post<any>(environment.base_url + `ch-trip/team/removeAdminAsLeader?teamId=${id}`,null);
    }

    //remove attendece from trip
    public removeUserFromTrip(data){
        return this.http.post<any>(environment.base_url + `ch-trips/delete/user?tripId=${data.tripID}&userIds=${data.IDs}`,null);
    }

    //approve reject photo
    public approveRejectPhoto(data){
        return this.http.post<any>(environment.base_url + `ch-trip-photo/approve/${data.id}?status=${data.status}`,null);
    }
    
    public getTripProvider(tripID) {
        return this.http.get<any>(environment.base_url + `ch-trip/provider?tripId=${tripID}`)
    }

}
