import { Component, OnInit,ViewChild,ElementRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { FormBuilder, FormGroup , Validators} from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { AppInfoService } from '../../services/appinfo.service';
import { UsersService } from '../../services/users.service';
import { TripService } from '../../services/trip.service';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { NgxImageCompressService } from 'ngx-image-compress';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    shouldShow: boolean = false;
    modalSettings: BsModalRef;
    modalAccount: BsModalRef;
    modalLicense: BsModalRef;
    modalChangepassword: BsModalRef;
    modalAbout: BsModalRef;
    modalPrivacy: BsModalRef;
    modalTerms: BsModalRef;
    modalNotifications: BsModalRef;
    modalGroupname: BsModalRef;
    modalFolder: BsModalRef;
    superAdmin = false;
    changePasswordForm:FormGroup;
    createEmptyFolderForm:FormGroup;
    resetPasswordSubmited:boolean = false;
    adminDetail:any = {
        imageUrl: "assets/images/profile-placeholder-image.svg"
    }
    aboutDetail:string = "";
    privacyDetail:string = "";
    termsDetail:string = "";
    editAdminForm:FormGroup;
    profileImageFile:any = null;
    selectedImage:any = "assets/images/profile-placeholder-image.svg";
    profileSumitted:boolean = false;
    isHidden = false;
    isEditing:boolean = false;


    manageActive:boolean = true;
    archiveActive:boolean = false;
    addNewActive:boolean = false;
    databaseActive:boolean = false;

    newTripStatus:number = 0;
    tripListing = [];
    Grouplisting = [];

    groupname:string = '';
    groupnameadd:string = '';
    @ViewChild('addgroupname') addgroupname: ElementRef;
    groupID:number;
    groupsubmitted:boolean = false;
    groupsubmittedupdate:boolean = false;
    Folders:any = [];
    GroupButtonName:string = 'Add New';
    ExistingGroupUsers:any = [];
    GroupUsers:any = [];

    queryParams:any = {};
    currentTrip:any = {};

    AdminNotifications:any = [];
    @ViewChild("groups",{ static: true }) groups: TemplateRef<any>;
    @ViewChild("folder",{ static: true }) folder: TemplateRef<any>;


    currentTripOptionDisplay:string = null;
    isCreateEmptyFolderSubmitted:boolean = false;
    folderbutton:string= 'Save';
    folderID:number;
    SelectedGroupIDForUsers:number;

    constructor(
        public router: Router,
        private modalService: BsModalService,
        private fb: FormBuilder,
        private adminService: AdminService,
        private toastr: ToastrService,
        private appInfoService: AppInfoService,
        private sidebarService: SidebarService,
        private userserive:UsersService,
        private ngxLoader: NgxUiLoaderService,
        private tripservice:TripService,
        private activatedRoute: ActivatedRoute,
        private imageCompress: NgxImageCompressService,
    ) { }
    
    settingsModal(settings: TemplateRef<any>) {
        this.modalSettings = this.modalService.show(settings, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }
    accountModal(account: TemplateRef<any>) {
        this.isHidden = true
        this.modalAccount = this.modalService.show(account, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }
    licenseModal(license: TemplateRef<any>) {
        this.modalLicense = this.modalService.show(license, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }
    changepasswordModal(changepassword: TemplateRef<any>) {
        this.modalChangepassword = this.modalService.show(changepassword, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }
    aboutModal(about: TemplateRef<any>) {
        this.getAbout();
        this.modalAbout = this.modalService.show(about, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }
    privacyModal(privacy: TemplateRef<any>) {
        this.getPrivacy();
        this.modalPrivacy = this.modalService.show(privacy, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }
    termsModal(terms: TemplateRef<any>) {
        this.getTerms();
        this.modalTerms = this.modalService.show(terms, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }
    notificationsModal(notifications: TemplateRef<any>) {
        this.getAllNotifications();
        this.modalNotifications = this.modalService.show(notifications, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }
    groupnameModal(groups: TemplateRef<any>) {
        this.modalGroupname = this.modalService.show(groups, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }

    /*---------------------
    | Folder Listing
    ----------------------*/
    folderlisting(folder: TemplateRef<any>) {
        this.modalFolder = this.modalService.show(folder, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))   
    }


    ngOnInit() {
        let userData = JSON.parse(localStorage.getItem('cuserData'))
        this.adminDetail = {...this.adminDetail,...userData.chUserDTO}

        if(this.adminDetail.imageUrl) {
            this.selectedImage = this.adminDetail.imageUrl;
        }
        if(!this.adminDetail.imageUrl) {
            this.adminDetail.imageUrl = "assets/images/profile-placeholder-image.svg";
        }
        if(userData.chUserDTO.userType == "SUPER_ADMIN"){
            this.superAdmin = true
        }else{
            this.superAdmin = false
        }
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.queryParams = params;
        });

        this.changePasswordForm = this.fb.group({
            currentPassword: ['', Validators.required],
            newPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        });
        this.editAdminForm = this.fb.group({
            email: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            organization: ['', Validators.required],
            phone: ['', Validators.required],
            position: ['', Validators.required],
        });
        this.editAdminForm.patchValue(this.adminDetail)

        this.sidebarService.getTripStatus().subscribe((status)=>{
            this.newTripStatus = status;
        });

        this.sidebarService.getSideBarTrips().subscribe((data)=>{
            this.tripListing = data;
            // this.getFolders();
        });
        
        this.sidebarService.getSideBarFolders().subscribe((data)=>{
            this.Folders = data;
        });

        this.sidebarService.getSideBarGroups().subscribe((data)=>{
            //console.log("data",data)
            this.groupsubmitted = false;
            this.Grouplisting = data;
        });

        this.setSidebarOption();

        this.sidebarService.getOpenTripSidebarOption().subscribe(name => {
            this.openTripOption(name);
        })
        
        if(this.queryParams.trip && !this.tripListing.length) {
            this.tripservice.getTripDetails(this.queryParams.trip).subscribe((response) => {
                this.currentTrip = response.data;
            })    
        }

        this.createEmptyFolderForm = this.fb.group({
            folderName: ['', [Validators.required]],
        });

        

    }

    openTripOption(name) {
        this.currentTripOptionDisplay = name;  
    }

    addnewGroup(type){
        // if(this.groupname != ''){
            if(type == "add"){
                this.groupsubmitted = true;
                if(this.groupname != ''){
                    this.ngxLoader.start();
                    const groupvalue = {chUserId:[],groupName:this.groupname}
                    this.userserive.addNewGroup(groupvalue).subscribe((option) => {
                        this.toastr.success(option.message, 'Success');
                        this.ngxLoader.stop();
                        this.groupsubmitted = false;
                        this.groupname = '';
                        this.addgroupname.nativeElement.value = '';
                        this.getGroupListing();
                    })
                }
            }else{
                this.groupsubmittedupdate = true;
                if(this.groupnameadd != ''){
                    this.ngxLoader.start();
                    const groupvalue = {groupName:this.groupnameadd,id:this.groupID}
                    this.userserive.updatefolderName(groupvalue).subscribe((option) => {
                        this.toastr.success(option.message, 'Success');
                        this.ngxLoader.stop();
                        this.groupsubmittedupdate = false;
                        this.modalGroupname.hide();
                        this.getGroupListing();
                    })
                }
            }
        // }
    }

    // createEmptyFolder() {
    //     this.sidebarService.setEmptyFolder({status:true});
    // }

    getGroupListing(){
        this.userserive.getAllGroups().subscribe((res) => {
            this.Grouplisting = res.data;
            this.sidebarService.setSideBarGroupsFromSideBar(res.data);  
        })
    }

    /* Edit Group Details */
    // editGroup(group){
    //     this.GroupButtonName = 'Update Group'
    //     this.groupname = group.groupName;
    //     this.groupID = group.id;
    // }
    
    /* Edit Group Details */
    editGroupName(group){
        this.GroupButtonName = 'Update Group'
        this.groupnameadd = group.groupName;
        this.groupID = group.id;
        this.modalGroupname = this.modalService.show(this.groups, Object.assign({}, { class: 'modal-lg modal-dialog-centered' })) 
        // this.groupnamemodal.show();
    }


    /* Delete Group*/
    deleteGroup(id){
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want to delete this group ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
            }).then((result) => {
            if (result.value) {
                this.ngxLoader.start();
                this.userserive.deleteGroup(id).subscribe((option) => {
                    this.toastr.success(option.message, 'Success');
                    this.sidebarService.setGroupStatusOption(true);
                    this.ngxLoader.stop();
                    this.getGroupListing();
                })
            }
        })
    }

    removeUserFromGroup(user){
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure you want remove selected user from this group ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
            }).then((result) => {
            if (result.value) {
                this.ngxLoader.start();
                this.userserive.removeUserFromGroup({user_id:user.id,group_id:this.SelectedGroupIDForUsers}).subscribe((option) => {
                    this.toastr.success(option.message, 'Success');
                    this.sidebarService.setGroupStatusOption(true);
                    this.ngxLoader.stop();
                    this.getGroupListing();
                })
            }
        })
    }

    /* Edit Group Details */
    editFolder(folder){
        // const data = {status:true,data:folder};
        if(folder && Object.keys(folder).length){
            this.folderID = folder.id
            this.createEmptyFolderForm.patchValue({ 
                folderName: folder.name
            });
            this.folderbutton = 'Update';
            this.modalFolder = this.modalService.show(this.folder, Object.assign({}, { class: 'modal-lg modal-dialog-centered' })) 
        }
        // this.sidebarService.setEmptyFolder(data);
        // folder.mode = 'edit';
        // this.sidebarService.setSideBarFoldersFromSideBar(folder); 
        // console.log("here is folder",folder);
    }

      /*---------------------
    | Create Empty Folder
    ----------------------*/
    createEmptyFolder() {
        this.isCreateEmptyFolderSubmitted = true;
        if(!this.createEmptyFolderForm.value.folderName.trim()) {
            this.createEmptyFolderForm.patchValue({ folderName: '' });
            return;
        }
        if(this.createEmptyFolderForm.valid) {
            this.ngxLoader.start();
            if( this.folderbutton == 'Save'){
                this.tripservice.addnewFolder({ folderName:this.createEmptyFolderForm.value.folderName.trim(),tripIds:[] }).subscribe((response) => {
                    if(response.code && response.code == 400) {
                        this.ngxLoader.stop();
                        this.toastr.warning(response.message, 'warning');
                        return;
                    }
                    this.toastr.success(response.message, 'Success');
                    this.isCreateEmptyFolderSubmitted = false;
                    this.createEmptyFolderForm.reset();
                    this.ngxLoader.stop();
                    this.modalFolder.hide();
                    this.getFolders();
                },(error) => {
                    this.modalFolder.hide();
                    this.ngxLoader.stop();
                    this.toastr.error("Server error, try again.", 'Error')
                })
            }else{
                this.tripservice.updateFolder({ folderName:this.createEmptyFolderForm.value.folderName.trim(),id:this.folderID }).subscribe((response) => {
                    if(response.code && response.code == 400) {
                        this.ngxLoader.stop();
                        this.toastr.warning(response.message, 'warning');
                        return;
                    }
                    this.toastr.success(response.message, 'Success');
                    this.isCreateEmptyFolderSubmitted = false;
                    this.folderbutton = 'Save';
                    this.createEmptyFolderForm.reset();
                    this.ngxLoader.stop();
                    this.modalFolder.hide();
                    this.getFolders();
                },(error) => {
                    this.modalFolder.hide();
                    this.ngxLoader.stop();
                    this.toastr.error("Server error, try again.", 'Error')
                })

            }
        }
    }


    /*---------------------
    | Get Group Users
    ----------------------*/
    getGroupUsers(groupID,event){
        let node = event.srcElement.parentNode
        if(event.srcElement.parentNode.classList.contains('folder-heading')) {
            node = event.srcElement.parentNode.parentNode
        }
        if(!node.classList.contains('active')){
            this.ngxLoader.start();
            this.userserive.getGroupUsers(groupID).subscribe((response) => {
                this.SelectedGroupIDForUsers = groupID;
                this.GroupUsers = response.data.users;
                this.ngxLoader.stop();
            })
        }
    }   


    /* Delete Folder */
    deleteFolder(id){
        this.ngxLoader.start();
        const data = {folderIds:[id]};
        this.userserive.deleteFolder(data).subscribe((option) => {
            this.sidebarService.notifyFolderDeleted(option);
            // this.toastr.success(option.message, 'Success');
            this.ngxLoader.stop();
            this.getFolders();
        })
    }

    getFolders(){
        this.tripservice.getAllFolders().subscribe((res) => {
            this.Folders = res.data;
            this.sidebarService.notifyFolderDeleted(res);
            // this.sidebarService.setSideBarFoldersFromSideBar(res.data);  
        })
    }

    /* get admin notifications */
    getAllNotifications(){
        this.ngxLoader.start();
        this.userserive.getNotifications().subscribe((res) => {
            this.ngxLoader.stop();
            // this.AdminNotifications = res.data;
            this.AdminNotifications = res.data.map((notification) => {
                notification.timestamp = moment(notification.createdDate).unix();
                return notification;
            });

            this.AdminNotifications = this.AdminNotifications.sort((a,b) => {
                return b.timestamp -  a.timestamp;
            })

            let lastItenaryDate = '';
            let finalItenaryList = [];
            this.AdminNotifications.map((itinerary,index)=> {
                if(lastItenaryDate == moment(itinerary.createdDate).format('D')) {
                    finalItenaryList[finalItenaryList.length-1].push(itinerary);               
                } else {
                    finalItenaryList[finalItenaryList.length] = [itinerary];
                    lastItenaryDate = moment(itinerary.createdDate).format('D');
                }
            })
            this.AdminNotifications = finalItenaryList;

            if(this.AdminNotifications.length > 0){
                var todayDate = moment().format('L');  
                var dateOne = moment(this.AdminNotifications[0][0].createdDate).format('L');
                if(todayDate == dateOne){
                    this.AdminNotifications[0][0].istoday = true;
                }
                if(this.AdminNotifications.length > 1){
                    var todayDate = moment().subtract(1, 'days').format('L');  
                    var dateOne = moment(this.AdminNotifications[1][0].createdDate).format('L');
                    if(todayDate == dateOne){
                        this.AdminNotifications[1][0].isyesterday = true;
                    }
                }
            }
        })
    }

    // /* get admin notifications */
    // compareForDates(date,istoday){
    //     if(istoday){
    //         var todayDate = new Date(); //Today Date    
    //         var dateOne = new Date(date); 
    //         if(todayDate == dateOne){
    //             return 'Today'
    //         }else{

    //         }
    //     }else{
    //         let dte = new Date();
    //         dte.setDate(dte.getDate() - 1);  
    //         var dateOne = new Date(date); 
    //         if(dte == dateOne){
    //             return 'Today'
    //         }else{

    //         }
    //     }
    // }

    setSidebarOption() {
        this.sidebarService.getSidebarOption().subscribe((option) => {
            this.changeSidebartwo(option);
        })
    }

    // getTripName(id){
    //     const TripDetails =  this.tripListing.find(x => x.chTrip.id == id);
    //     console.log("here are filter trip",TripDetails);
    // }

    checkTarget(event) {
      
        if(event.target.id == "Ellipse_41" || event.target.id == "Ellipse_27") {return }

        
        let node = event.srcElement.parentNode
        if(event.srcElement.parentNode.classList.contains('folder-heading')) {
            node = event.srcElement.parentNode.parentNode
        }

        if( 
            (
                node.classList.contains('toggle') ||
                node.classList.contains('sub-menu-wrap')
            )   &&
                node.classList.contains('active')
            ) {
                
                node.classList.remove('active')
                return
        }

        let result = [];
        node = event.srcElement.parentNode.parentNode.firstChild;
        if(event.srcElement.parentNode.classList.contains('folder-heading')) {
            node = event.srcElement.parentNode.parentNode.parentNode.firstChild;
        }
        
        while ( node.nextElementSibling || node.nextSibling) {
            if( 
                (
                  node.classList.contains('toggle') ||
                  node.classList.contains('sub-menu-wrap')
                )  &&
                node.classList.contains('active')
            ) {
                
                node.classList.remove('active')
                
            }
            node = node.nextElementSibling || node.nextSibling;
        }
        
        if(
            event.srcElement.parentNode.classList.contains('toggle') || 
            event.srcElement.parentNode.classList.contains('sub-menu-wrap')
          ) {
            event.srcElement.parentNode.classList.add('active')
        } else if(event.srcElement.parentNode.parentNode.classList.contains('sub-menu-wrap')) {
            event.srcElement.parentNode.parentNode.classList.add('active')
        }
    }



    changeSidebartwo(data) {
        if(data == "manage") {
            this.manageActive = true;
            this.archiveActive = false;
            this.addNewActive = false;
            this.databaseActive = false;
            this.shouldShow = true;
        }
        if(data == "archive") {
            this.manageActive = false;
            this.archiveActive = true;
            this.addNewActive = false;
            this.databaseActive = false;
            this.shouldShow = true;
        }
        if(data == "addnew") {
            this.manageActive = false;
            this.archiveActive = false;
            this.addNewActive = true;
            this.databaseActive = false;
            this.shouldShow = true;
        } 
        if(data == "database") {
            this.databaseActive = true;
            this.manageActive = false;
            this.archiveActive = false;
            this.addNewActive = false;
            this.shouldShow = true;
        }
        this.sidebarService.changeStatus(this.shouldShow)
    }

    logout(){
        this.userserive.deleteWebToken().subscribe((res) => {
            console.log("token delete");
        })
        this.modalSettings.hide()
        localStorage.removeItem("cuserData");
        this.router.navigate(['login'])
    }
    
    sidebarToggle() {
        this.shouldShow = !this.shouldShow
        this.sidebarService.changeStatus(this.shouldShow)
    }

    getAbout() {
        this.appInfoService.getCms('about').subscribe((response) => {
            this.aboutDetail = response.data.cmsDescription
        },(error) => { 
            this.toastr.error("Unable to get About Infomation ", 'Error')
        })
    }
    getPrivacy() {
        this.appInfoService.getCms('privacyPolicy').subscribe((response) => {
            this.privacyDetail = response.data.cmsDescription
        },(error) => { 
            this.toastr.error("Unable to get privacy policy Infomation ", 'Error')
        })
    }
    getTerms() {
        this.appInfoService.getCms('terms').subscribe((response) => {
            this.termsDetail = response.data.cmsDescription
        },(error) => { 
            this.toastr.error("Unable to get Terms and Condition Infomation ", 'Error')
        })
    }

    openFromProfile(account: TemplateRef<any>){
        this.isHidden = false
        this.modalAccount = this.modalService.show(account, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }))
    }

    imgErrorHandler(event) {
        event.target.src = "assets/images/profile-placeholder-image.svg";
    }
    edit() {
        this.isEditing = true;
    }
    changePassword() {
     
        this.resetPasswordSubmited = true;
        if(this.changePasswordForm.valid) {
            if(this.changePasswordForm.value.newPassword != this.changePasswordForm.value.confirmPassword) {
                return;
            }
            
            this.adminService.changePassword({
                currentPassword: this.changePasswordForm.value.currentPassword,
                newPassword: this.changePasswordForm.value.newPassword
            }).subscribe((response) => {
                this.resetPasswordSubmited = false;
                if(response.code == 400) {
                    this.toastr.error(response.message, 'Error')
                } else {
                    this.toastr.success(response.message, 'Success');
                    this.changePasswordForm.reset();
                    this.modalChangepassword.hide();
                }
            },(error) => {
                this.toastr.error("Unable to change password", 'Error')
            })
        }
       
    }


    updateProfile() {
        this.profileSumitted = true;
        this.isEditing = false;
        
        if(this.editAdminForm.valid) {
            var formData = new FormData();
            if(this.profileImageFile) {
                formData.append('image',this.profileImageFile);
            }
            formData.append('data', new Blob([JSON.stringify({...this.editAdminForm.value,id:this.adminDetail.id,maxUser:null})], { type: "application/json" }));
            this.adminService.updateAdmin(formData).subscribe((response) => {

                this.editAdminForm.patchValue(response.data);
                this.adminDetail = {...this.adminDetail,...response.data}
                let userData = JSON.parse(localStorage.getItem('cuserData'));
                userData.chUserDTO = this.adminDetail;
                localStorage.setItem("cuserData",JSON.stringify(userData)); 
                if(!this.adminDetail.imageUrl) {
                    this.adminDetail.imageUrl = "assets/images/profile-placeholder-image.svg";
                }

                this.profileSumitted = false;
                this.toastr.success(response.message, 'Success');
       
            },(error) => {
                this.toastr.error("Unable to update profile", 'Error');
            })
        }
    }

    // onSelectImage(event) {
    //     if (event.target.files && event.target.files[0] && event.target.files[0].type.split('/')[0] == 'image') {
    //         var reader = new FileReader();

    //         this.profileImageFile = event.target.files[0];

    //         reader.readAsDataURL(event.target.files[0]);

    //         reader.onload = (event) => {
    //             this.selectedImage = event.target.result;
    //         }
    //     }
    // }
    onFileSelect(){
        this.imageCompress.uploadFile().then(({image, orientation}) => {
           
            this.imageCompress.compressFile(image, orientation, 50, 50).then(
              result => {
                var formData = new FormData();
                const file = this.DataURIToBlob(result)
                this.createImageFromBlob(file);
        
                this.profileImageFile = file;
                
              }
            );
        });
    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
        this.selectedImage = reader.result;
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    DataURIToBlob(dataURI: string) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
    
        return new Blob([ia], { type: mimeString })
    }
}
